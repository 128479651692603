







import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class ImageFormat extends Vue {
  @Prop()
  path!: string;
  @Prop({ default: "" })
  alt!: string;

  replacePngJpg(path: string) {
    return path.replace(/png|jpg$/, "webp");
  }
}
